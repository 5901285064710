<script>

import AtModal from "@/components/AtModal.vue";
import api from "@/repo/api";
import AtInput from "@/components/AtInput.vue";

export default {
    components: {AtInput, AtModal},
    props: {
        app: Object,
    },
    mounted() {
        this.appInfo = this.app
        
        console.log(this.appInfo);
    },
    watch: {
        app() {
            this.appInfo = this.app
        }
    },
    data() {
        return {
            showNameModal: false,
            nameError: '',
            params: {
                name: ""
            },
            appInfo: null,
        }
    },
    methods: {
        showName() {
            if (this.nickNameModLost > 0) return this.reloadAppInfo()
            
            this.$message.error('小程序修改名称次数已耗尽.')
        },
        checkTodayIsEdit(key) {
            
            return new Promise((resolve, reject) => {
                
                api.post('/ast-app/check-today-is-edit', {key}, (response) => {
                    
                    if (response.code !== 0) {
                        this.$message.error(response.msg)
                        
                        reject();
                        return;
                    }
                    
                    resolve(response);
                })
            })
        },
        reloadAppInfo() {
            
            this.$loading.show()
            
            this.checkTodayIsEdit().then(() => {
                
                api.get('/ast-app/app-info', (data) => {
                    
                    this.appInfo = data.data
                    
                    this.$loading.hide()
                    
                    this.params = {
                        name: ''
                    }
                    
                    this.showNameModal = true
                    
                });
                
            })
        },
        nameConfirm() {
            
            this.$loading.show()
            
            api.post('/ast-app/update-name', this.params, (data) => {
                
                this.$loading.hide()
                
                if (data.code === 0) {
                    
                    this.showNameModal = false
                    
                    this.$message.success('修改成功,页面即将刷新...')
                    
                    setTimeout(() => {
                        location.reload()
                    }, 2000)
                    
                } else {
                    
                    this.nameError = data.msg
                    
                }
            })
        },
    },
    computed: {
        nickNameModLost() {
            
            if (!this.app) return 0;
            
            if (!this.app.nickname_info) return 0;
            
            return this.app.nickname_info.modify_quota - this.app.nickname_info.modify_used_count
        }
    }
}
</script>

<template>
    <div>
        <a class="bs-edit" href="javascript:"
           @click="showName">修改</a>
        <at-modal title="修改名称" width="408px" @ok="nameConfirm" v-model="showNameModal">
            
            <div class="xs-item">
                <div class="xs-title mb-4">请输入小程序名称</div>
                <div class="xs-content summary-content">
                    <at-input :maxLength="20" class="full-with" v-model="params.name"></at-input>
                </div>
            </div>
            
            <div class="mt-2">
                <small v-if="appInfo && appInfo.nickname_info ">
                    一年内可申请修改{{ appInfo.nickname_info.modify_quota }}次,
                    今年还可修改{{ nickNameModLost }}次
                </small>
            </div>
            
            <template v-if="nameError">
                <div class="summary-warning-1">
                    <template>{{ nameError }}</template>
                </div>
                <div class="summary-warning-2">
                    请更换其他名称或前往
                    <a target="_blank" href="https://mp.weixin.qq.com/">微信公众平台</a>
                    修改
                </div>
            
            </template>
        
        </at-modal>
    </div>
</template>

<style scoped lang="less">

</style>