<template>
    <div class="deck-page">
        
        <div class="mode-selector" v-if=" showModelSelector">
            <transition name="fadeIn">
                <div class="mode-selector-bg" v-if="showModelSelectorContent"></div>
            </transition>
            <transition name="slideInDown">
                <div class="mode-selector-content" v-if="showModelSelectorContent">
                    <div class="mode-selector-title">请选择拼图模式</div>
                    <div class="mode-selector-img">
                        <img src="/assets/images/scheme-model-select.png" alt="">
                    </div>
                    <div class="mode-btn" @click="modelSelected('professional')">专业拼图模式</div>
                    <div class="mode-btn" @click="modelSelected('simple')">快速简易模式</div>
                </div>
            </transition>
        </div>
        
        <div class="page-left">
            <div class="deck-area-box">
                <div class="deck-area-head">
                    <div class="copy-box">
                        <img src="/assets/images/deck/icon1.png" @click="imageDeck.preview()">
                        <img src="/assets/images/deck/icon2.png" @click="imageDeck.showTemplate()">
                    </div>
                    <div class="flex">
                        <div v-if=" schemeModel !== 'simple' " class="auto-btn" @click="autoScheme()">
                            <img src="/assets/images/deck/icon3.png">
                            <span>自动拼图</span>
                        </div>
                        <div class="mode-switch-btn" @click="activeModeSelector"></div>
                    </div>
                </div>
                <div class="deck-area" :class="schemeModel">
                    
                    
                    <template v-if="schemeModel === 'simple' ">
                        
                        <image-deck-simple :mode="schemeModel" @deleted="deckImageDeleted" :goodsList="selectedGoods"
                                           :images="deckImages"
                                           ref="imageDeckSimple"/>
                    
                    </template>
                    <template v-else>
                        
                        <image-deck :mode="schemeModel" @deleted="deckImageDeleted" :goodsList="selectedGoods"
                                    :images="deckImages"
                                    ref="imageDeck"/>
                    </template>
                
                </div>
                <div class="deck-area-tool"></div>
            </div>
        </div>
        <div class="page-right">
            <div class="goods-select">
                <div class="item" @click="goodsShopActive = true">
                    <img src="/assets/images/deck/icon6.png">
                    <span>自营店铺</span>
                </div>
                <div class="item" @click="goodsStockActive = true">
                    <img src="/assets/images/deck/icon4.png">
                    <span>商品库</span>
                </div>
                <div class="item" @click="goodsCollectActive = true">
                    <img src="/assets/images/deck/icon5.png">
                    <span>收藏夹</span>
                </div>
            </div>
            
            <template v-if="loadingOutfit">
                <img width="48" src="/assets/images/loading.gif">
            </template>
            <template v-else-if="selectedGoods.length === 0">
                <goods-empty></goods-empty>
            </template>
            <template v-else>
                <perfect-scrollbar :options="{ suppressScrollY: false }" class="goods-scroll" :class="schemeModel">
                    <div class="goods-list">
                        
                        <div @click="top(goods)" class="goods-block" v-for="(goods, key) in selectedGoods"
                             :key="goods.id"
                             :class="{ 'used': usedGoods.indexOf(goods) >= 0, 'third': (key + 1) % 3 === 0 }">
                            
                            <button @click.stop="deleteGoods(goods)" class="del-btn"><span
                                class="iconfont pc-guanbi"></span></button>
                            
                            <goods-item disable :goods="goods"/>
                        </div>
                        
                        <div></div>
                        <div></div>
                        <div></div>
                    
                    </div>
                </perfect-scrollbar>
                
                <div class="next">
                    <button @click="next" class="btn">下一步</button>
                </div>
            </template>
        
        
        </div>
        
        <drawer width="720px" v-model="goodsStockActive">
            <goods-stock :selected-list="selectedGoods" type="goods" @selected="goodsSelected"/>
        </drawer>
        
        <drawer width="720px" v-model="goodsCollectActive">
            <goods-stock :selected-list="selectedGoods" type="collect" @selected="goodsSelected"/>
        </drawer>
        
        <drawer width="720px" v-model="goodsShopActive">
            <goods-stock :selected-list="selectedGoods" type="shop" @selected="goodsSelected"/>
        </drawer>
    </div>
</template>

<script>
import api from "@/repo/api";
import auth from "@/auth";
import Drawer from "@/components/Drawer";
import GoodsItem from "@/components/GoodsItem";
import GoodsStock from "./Com/GoodsStock.vue";
import ImageDeck from "./Com/ImageDeck.vue";
import GoodsEmpty from "@/views/AST/Shop/Com/GoodsEmpty.vue";
import ImageDeckSimple from "@/views/AST/Shop/Com/ImageDeckSimple.vue";

export default {
    components: {ImageDeckSimple, GoodsEmpty, GoodsStock, Drawer, GoodsItem, ImageDeck},
    data() {
        return {
            // schemeModel: "professional",
            // schemeModel: "simple",
            schemeModel: null,
            hideMenu: true,
            showSchemeModal: true,
            showModelSelector: false,
            showModelSelectorContent: false,
            selectedGoods: [],
            usedGoods: [],
            deckImages: [],
            drawerActive: false,
            goodsStockActive: false,
            goodsShopActive: false,
            goodsCollectActive: false,
            goodsCustomActive: false,
            outfit: null,
            goods: [],
            loadingOutfit: false,
            totalPrice: '0.00',
            totalComm: '0.00',
            appId: auth.appId(),
            detailId: 0,
            schemeType: 'bang',
            showDetailQrcode: false,
            shopGoodsTypes: [
                'wechat',
                'yc-shop',
            ]
        }
    },
    watch: {
        '$route.query'() {
            this.showDetailQrcode = false
            this.getOutfit()
        },
        usedGoods() {
            let prices = 0;
            let comm = 0
            
            this.usedGoods.forEach(item => {
                
                prices += parseFloat(item.lowest_price || (item.min_price / 100).toFixed(2))
                comm += parseFloat(item.commission || 0.00)
                
            })
            
            this.totalPrice = prices.toFixed(2)
            this.totalComm = comm.toFixed(2)
        },
    },
    mounted() {
        this.getOutfit()
        this.getGoods()
        
    },
    computed:{
        imageDeck(){
            if (this.schemeModel == "simple") {
                return this.$refs['imageDeckSimple']
            }else{
                return this.$refs['imageDeck']
            }
        }
    },
    methods: {
        autoScheme() {
            
            this.$swal.fire({
                title: '请稍候...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
            })
            
            let buildList = this.imageDeck.getFrames();
            let frameGoods = [];
            
            buildList.forEach((bitem) => {
                
                this.usedGoods.forEach(item => {
                    
                    if (bitem.image !== item.white_image) return
                    
                    let frame = {
                        w: parseInt(bitem.style.width),
                        h: parseInt(bitem.style.height),
                    }
                    if (item.yc_cate_id) {
                        frame.cate_id = item.yc_cate_id;
                    }
                    
                    frameGoods.unshift(frame)
                })
                
            })
            
            api.post('/ast-scheme/auto-match-frame', {goods: frameGoods}, (data) => {
                
                this.$swal.close()
                
                if (data.code === 0 && data.data.length > 0) {
                    this.matchFrames(data.data[0])
                } else {
                    if (data.data.length == 0) {
                        // 没有可自动拼图的模式
                        this.$swal({
                            title: '没有可自动拼图的模式',
                            icon: 'error',
                            confirmButtonText: '好'
                        });
                    }
                }
                
            });
            
        },
        cj(old, newer) {
            
            let arr = []
            
            if (!newer.length) return [].concat(old)
            
            if (!old.length) return [].concat(newer)
            
            newer.forEach(nitem => {
                
                let find = false
                
                old.forEach(oitem => {
                    
                    if (oitem.id === nitem.id) find = true
                })
                
                if (!find) arr.push(nitem)
            })
            
            old.forEach(nitem => {
                
                let find = false
                
                newer.forEach(oitem => {
                    
                    if (oitem.id === nitem.id) find = true
                })
                
                if (!find) arr.push(nitem)
            })
            
            
            return arr
        },
        deckImageDeleted(image) {
            
            let idx = this.deckImages.indexOf(image)
            if (idx < 0) return
            
            this.deckImages.splice(idx, 1)
            
            idx = -1;
            
            this.usedGoods.forEach((item, key) => {
                if (item.white_image !== image) return
                idx = key
            })
            
            if (idx < 0) return
            
            this.usedGoods.splice(idx, 1)
        },
        deckSave() {
            let data = {};
            
            this.$swal.fire({
                title: '请稍候...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
            })
            
            if (this.outfit) {
                data['id'] = this.outfit.id
                data['type'] = this.outfit.type
            }
            console.log(111,this.imageDeck);
            
            this.imageDeck.build((img, buildList) => {
                
                console.log('buildList', buildList);
                
                // data['image'] = img
                
                localStorage.setItem('image', img);
                
                let goods = []
                let astGoods = []
                let frames = {
                    "width": "600",
                    "height": "600",
                }
                let frameGoods = []
                
                buildList.forEach((bitem) => {
                    this.usedGoods.forEach(item => {
                        
                        if (
                            bitem.image !== item.white_image
                        ) return
                        
                        goods.push(item.id)
                        
                        let type = 'bang'
                        let iid = [];
                        
                        
                        if (typeof item.id == 'string') {
                            iid = item.id.split('-')
                        } else {
                            iid = [item.id, '']
                        }
                        
                        
                        if (this.shopGoodsTypes.indexOf(item.from) >= 0) {
                            type = 'shop'
                        }
                        
                        astGoods.push({
                            id: iid[0],
                            type,
                            color: iid[1] || '',
                            from: item.from
                        })
                        let frame = {
                            x: parseInt(bitem.style.left),
                            y: parseInt(bitem.style.top),
                            w: parseInt(bitem.style.width),
                            h: parseInt(bitem.style.height),
                            id: item.id,
                            z: bitem.style.zIndex,
                            cs: bitem.contentStyle,
                        }
                        if (item.yc_cate_id) {
                            frame.cate_id = item.yc_cate_id;
                        }
                        frameGoods.unshift(frame)
                    })
                    
                })
                
                frames['mode'] = this.schemeModel
                frames['goods'] = frameGoods
                data['frame'] = frames
                data['type'] = 'shop'
                
                if (this.appId) {
                    
                    data['goods'] = astGoods;
                    
                    this.$swal.close();
                    
                    this.$router.push('/ast/shop/scheme/info?data=' + JSON.stringify(data))
                    
                }
            })
        },
        deleteGoods(item) {
            
            let idx = this.selectedGoods.indexOf(item)
            
            this.selectedGoods.splice(idx, 1)
            
            idx = this.usedGoods.indexOf(item)
            if (idx >= 0) this.usedGoods.splice(idx, 1)
            
            this.reloadGoodsImage()
        },
        getGoods() {
            let id = this.$route.query.goods
            
            if (!id) return;
            
            api.get('/goods/ids', {ids: id}, (data) => {
                
                if (data.code === 0 && data.data.data.length) {
                    this.selectedGoods = data.data.data
                    this.usedGoods = data.data.data
                    this.reloadGoodsImage()
                }
                
            });
            
        },
        modelSelected(mode) {
            
            this.schemeModel = mode
            
            localStorage.setItem('scheme_used_mode', mode)
            
            this.showModelSelectorContent = false
            
            if (this.usedGoods.length) {
                this.reloadGoodsImage()
            }
            
            setTimeout(() => {
                this.showModelSelector = false
            }, 350)
        },
        loadMode() {
            const mode = localStorage.getItem('scheme_used_mode')
            
            if (mode) {
                this.schemeModel = mode
            } else {
                this.activeModeSelector()
            }
        },
        activeModeSelector() {
            this.showModelSelector = true
            this.$nextTick(() => {
                this.showModelSelectorContent = true
            })
        },
        getOutfit() {
            
            let id = this.$route.query.outfit_id
            
            if (!id && !this.outfit) {
                this.loadMode()
                return
            }
            
            if (!id && this.outfit) {
                return location.reload()
            }
            if (id && this.outfit && this.outfit.id !== id) {
                return location.reload()
            }
            
            this.loadingOutfit = true
            
            api.get('/ast-scheme/scheme-detail', {id}, (data) => {
                
                this.loadingOutfit = false
                
                if (data.code !== 0) return
                
                data.data.goods.forEach(goods => {
                    
                    if ((this.shopGoodsTypes.indexOf(goods.from) === -1) || (!goods.color)) return;
                    
                    goods.title = goods.product_name + ' - ' + goods.color
                    goods.id = goods.id + '-' + goods.color
                    
                })
                
                this.loadOutfit(data.data)
            })
            
        },
        
        goodsSelected(goods) {
            
            this.goodsCollectActive = false
            this.goodsStockActive = false
            this.goodsShopActive = false
            this.goodsCustomActive = false
            
            if (!goods || !goods.length) return
            
            let unused = this.cj(this.selectedGoods, this.usedGoods)
            
            this.usedGoods = this.cj(unused, goods)
            
            this.selectedGoods = goods
            
            this.reloadGoodsImage()
        },
        
        loadOutfit(data) {
            
            this.outfit = data
            this.selectedGoods = data.goods
            this.usedGoods = data.goods
            
            if (data.frame && data.frame.frame) {
                
                let list = []
                
                let bl = 600 / data.frame.frame.width;
                
                this.schemeModel = data.frame.frame.mode || 'professional';
                
                let zh = (v) => {
                    return bl * v;
                }
                
                data.frame.frame.goods.forEach(item => {
                    
                    let image = '';
                    
                    this.usedGoods.forEach(goods => {
                        if (goods.id === item.id) image = goods.white_image
                    })
                    
                    if (!image) return;
                    
                    list.unshift({
                        type: 'image',
                        image: image,
                        style: {
                            left: zh(item.x) + 'px',
                            top: zh(item.y) + 'px',
                            width: zh(item.w) + 'px',
                            height: zh(item.h) + 'px',
                            zIndex: item.z
                        },
                        contentStyle: item.cs || {},
                        active: false,
                    })
                    
                })
                
                this.$nextTick(() => {
                    this.imageDeck.setImageData(list)
                })
                
            } else {
                this.schemeModel = 'professional'
                
                this.reloadGoodsImage()
            }
            
        },
        matchFrames(frameData) {
            
            let frame = frameData.frame.goods;
            
            let newFrameList = []
            
            this.usedGoods.forEach(item => {
                
                for (const frameKey in frame) {
                    
                    let subFrame = frame[frameKey];
                    
                    if (item.yc_cate_id != subFrame.cate_id) continue;
                    
                    frame.splice(frameKey, 1);
                    
                    newFrameList.push({
                        image: item.white_image,
                        type: "image",
                        active: false,
                        style: {
                            width: `${subFrame.w}px`,
                            height: `${subFrame.h}px`,
                            left: `${subFrame.x}px`,
                            top: `${subFrame.y}px`,
                            zIndex: 80 + frameKey,
                        }
                    });
                    
                    break;
                }
            })
            this.imageDeck.setFrames(newFrameList);
        },
        next() {
            
            if (!this.usedGoods.length) {
                
                this.$swal({
                    title: '请先完成拼图',
                    icon: 'error',
                    confirmButtonText: '好'
                });
                return
            }
            this.deckSave();
        },
        reloadGoodsImage() {
            
            let images = []
            
            this.usedGoods.forEach(item => {
                
                let image = item.white_image
                
                if (image) images.push(image)
            })
            
            this.deckImages = images
        },
        
        top(item) {
            
            if (this.usedGoods.indexOf(item) === -1) {
                
                this.usedGoods.push(item);
                
                this.reloadGoodsImage();
            }
            
            setTimeout(() => this.imageDeck.active(item.white_image), 50)
        }
    }
}
</script>

<style lang="less" scoped>
.deck-page {
    display: flex;
}

.page-left {
    margin-right: 20px;
}

.page-right {
    
    .next {
        margin-top: 32px;
    }
    
    .goods-scroll {
        height: 565px;
        margin-top: 24px;
        
        &.simple {
            height: 590px;
        }
    }
    
}

.deck-area-box {
    width: 644px;
    background: #F5F5F5;
    
    .deck-area {
        width: 600px;
        height: 600px;
        border: 1px dashed #000000;
        margin: 0 auto;
        
        &.simple {
            border: none;
            margin-top: 22px;
        }
    }
}

.deck-area-head {
    width: 644px;
    height: 48px;
    background: rgba(51, 51, 51, 0.04);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    
    .copy-box {
        cursor: pointer;
        
        img {
            width: 32px;
            height: 32px;
            margin-right: 12px;
        }
    }
    
    .auto-btn {
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: #FFFFFF;
        border-radius: 74px;
        cursor: pointer;
        
        img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
        }
        
        span {
            color: #333333;
            font-size: 14px;
        }
    }
}

.mode-switch-btn {
    width: 32px;
    height: 32px;
    background-image: url("/assets/icons/model-switch-btn.png");
    background-size: contain;
    cursor: pointer;
    margin-left: 24px;
}


.deck-area-tool {
    width: 644px;
    height: 48px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    
    .tool-item {
        height: 48px;
        display: flex;
        align-items: center;
        margin-right: 16px;
        
        img {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }
        
        span {
            color: #333333;
            font-size: 14px;
        }
    }
}

.goods-select {
    
    display: flex;
    align-items: center;
    
    .item {
        width: 106px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(96deg, #5F5F5F 0%, #333333 100%);
        margin-right: 16px;
        cursor: pointer;
        
        img {
            width: 20px;
            height: 20px;
            margin-right: 4px;
        }
        
        span {
            color: #FFFFFF;
            font-size: 14px;
        }
    }
}

.goods-list {
    width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
}

.goods-block {
    cursor: pointer;
    width: 200px;
    margin-bottom: 16px;
    position: relative;
    border: 1px solid #ffff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.06);
    
    &.third {
        margin-right: 0;
    }
    
    &:hover .del-btn {
        opacity: 1;
    }
    
    &.used {
        border-color: #333333;
    }
    
    .del-btn {
        width: 24px;
        height: 24px;
        background-color: white;
        border: 1px solid #EEEEEE;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 99;
        font-size: 12px;
        opacity: 0;
        
        span {
            font-size: 12px;
        }
    }
    
    /deep/ .goods-item {
        height: auto;
        overflow: hidden;
        
        .image-container {
            margin-bottom: 0;
        }
    }
    
    /deep/ .goods-detail {
        padding: 12px;
        line-height: 19px;
        
        .goods-name {
            margin-bottom: 8px;
        }
        
        .name {
            line-height: 16px;
            font-weight: 500;
        }
        
        .lowest {
            font-weight: 500;
            font-size: 16px;
            color: #E52553;
            line-height: 19px;
        }
    }
}

.mode-selector {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    .mode-selector-bg {
        background: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    
    .mode-selector-content {
        width: 320px;
        height: 330px;
        background: rgb(243, 243, 243);
        border-radius: 16px 16px 16px 16px;
        padding: 16px;
        position: relative;
        z-index: 1;
    }
    
    .mode-selector-img {
        text-align: center;
        margin-top: 23px;
        user-select: none;
        -webkit-user-drag: none;
        
        img {
            width: 224px;
        }
    }
    
    .mode-selector-title {
        font-weight: 500;
        font-size: 16px;
        color: #141414;
        line-height: 19px;
        text-align: center;
    }
    
    .mode-btn {
        &:first-child {
            margin-top: 37px;
        }
        
        margin-top: 12px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 40px;
        background: rgba(20, 20, 20, 0.5);
        height: 40px;
        border-radius: 8px;
        cursor: pointer;
        transition: all .3s;
        
        &:hover {
            background: #141414;
        }
    }
}


</style>