<template>
    <div class="mp" :class="{'mini':mini}">

        <div class="close" v-if="!mini" @click="mini = !mini "></div>

        <div class="mini shake-animation " v-else-if="mini" @click="mini = !mini ">
            <a-icon type="shake"/>
        </div>

        <div class="mp-content" v-if="!mini">

            <div class="guid" v-if="current === 'guide'">

                <mp-guid :app-config="appConfig"/>

            </div>

            <perfect-scrollbar class="mp-page">

                <template v-if="current === 'custom'">
                    <custom-index :app-config="appConfig"/>
                </template>
                <template v-else-if=" current === 'card' ">
                    <mp-card :app-config="appConfig"/>
                </template>
                <template v-else-if=" current === 'ai' ">
                    <mp-detect-ai :app-config="appConfig"/>
                </template>
                <template v-else-if=" current === 'detect' ">
                    <detect-style :app-config="appConfig"/>
                </template>
                <template v-else>
                    <div class="page-empty"> - 暂无该页预览 -</div>
                </template>

            </perfect-scrollbar>

            <div class="tab-bar">

                <template v-for="(tab,tk) in userTabs">

                    <div :key="tk" class="tab-bar-item" :class="{active: tab.name === current }">
                        <div class="tab-icon">
                            <img class="active-img" :src="`/assets${tab.activeIcon}`" alt="">
                            <img class="inactive-img" :src="`/assets${tab.inactiveIcon}`" alt="">
                        </div>
                        <div class="tab-name">{{ tab.title }}</div>
                    </div>

                </template>

            </div>

        </div>
    </div>
</template>

<script>
import CustomIndex from "@/views/AST/Xcx/Mp/MpPages/CustomIndex.vue";
import MpCard from "@/views/AST/Xcx/Mp/MpPages/MpCard.vue";
import MpDetectAi from "@/views/AST/Xcx/Mp/MpPages/MpDetectAi.vue";
import DetectStyle from "@/views/AST/Xcx/Mp/MpPages/DetectStyle.vue";
import MpGuid from "@/views/AST/Xcx/Mp/MpPages/MpGuid.vue";

export default {
    name: "Mp",
    components: {MpGuid, DetectStyle, MpDetectAi, MpCard, CustomIndex},
    props: {
        appConfig: Object,
        current: String,
    },
    data() {
        return {
            mini: false,
            userTabs: [],
            tabList: [{
                name: "custom",
                title: "首页",
                activeIcon: "/mp-static/images/home-black.png",
                inactiveIcon: "/mp-static/images/home-white.png",
                key: "page_custom_index_enabled",
                titleKey: "page_custom_index_name",
            }, {
                name: "card",
                title: "名片",
                activeIcon: "/mp-static/tabbar/user-tabbar1-1.png",
                inactiveIcon: "/mp-static/tabbar/user-tabbar1.png",
                key: "page_card_enabled",
            }, {
                name: "ai",
                title: "AI检测",
                activeIcon: "/mp-static/images/ai-black.png",
                inactiveIcon: "/mp-static/images/ai-white.png",
                key: "page_ai_enabled",
                path: "/packagesF/detectIndex/detectIndex"
            }, {
                name: "detect",
                title: "风格",
                activeIcon: "/mp-static/tabbar/fg-icon-1.png",
                inactiveIcon: "/mp-static/tabbar/fg-icon-2.png",
                key: "page_detect_enabled",
                path: "/packagesE/detectStyle/detectStyle"
            }, {
                name: "service",
                title: "服务",
                activeIcon: "/mp-static/tabbar/user-tabbar2-1.png",
                inactiveIcon: "/mp-static/tabbar/user-tabbar2.png",
                key: "page_service_enabled",
            }, {
                name: "shop",
                title: "商城",
                activeIcon: "/mp-static/tabbar/shop-active.png",
                inactiveIcon: "/mp-static/tabbar/shop.png",
                key: "page_shop_enabled",
            }, {
                name: "scheme",
                title: "搭配",
                activeIcon: "/mp-static/tabbar/user-tabbar3-1.png",
                inactiveIcon: "/mp-static/tabbar/user-tabbar3.png",
                key: "page_scheme_enabled",
            }, {
                name: "cases",
                title: "案例",
                activeIcon: "/mp-static/images/case-black.png",
                inactiveIcon: "/mp-static/images/case-white.png",
                key: "page_cases_enabled",
            }, {
                name: "my",
                title: "我的",
                activeIcon: "/mp-static/tabbar/user-tabbar4-1.png",
                inactiveIcon: "/mp-static/tabbar/user-tabbar4.png",
            },
            ],
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.initTabs()
        },
        initTabs() {
            let appConfig = this.appConfig
            let tabs = []

            for (let idx in this.tabList) {

                let tab = this.tabList[idx]

                if (appConfig[tab.key] === true || tab.name === 'my') {

                    if (tab.titleKey && appConfig[tab.titleKey]) {

                        tab.title = appConfig[tab.titleKey]
                    }

                    tabs.push(tab)

                    if (((idx + 1) === Number(this.tab)) || (tab.name === this.tab)) {
                        this.value = tab.name
                    }
                }
            }

            this.userTabs = tabs;
        }
    }
}
</script>

<style scoped lang="less">


@keyframes shake {
    0% {

        transform: rotate(0deg);

    }
    25% {

        transform: rotate(10deg);

    }
    50% {

        transform: rotate(0deg);

    }

    75% {

        transform: rotate(-10deg);

    }

    100% {

        transform: rotate(0deg);

    }
}

.shake-animation {
    animation: shake .5s; /* 摇摆动画，持续时间0.5秒 */
    animation-iteration-count: infinite; /* 动画无限次数播放 */
}

.mp {
    width: 414px * 0.75;
    height: 811px * 0.75;
    background-image: url("/assets/shouji.png");
    background-size: 100% 100%;
    padding: 15px 16px 16px 16px;
    position: relative;
    pointer-events: auto;
    &.mini {
        width: 0;
        height: 0;
        padding: 0;
    }
}

.mp-content {
    background-color: #eee7db;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.tab-bar {
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-auto-flow: column;
    grid-auto-columns: max-content;

    grid-template-columns:repeat(auto-fit, minmax(20px, 1fr));

    background-color: #FFFFFF;

    box-sizing: border-box;

    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 15px;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.tab-bar-item {
    grid-template-columns: 1fr;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .tab-name {
        font-size: 12px;
        color: #606266;
    }

    .tab-icon {
        width: 22px;
        height: 22px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .active-img {
        display: none;
    }

    .inactive-img {
        display: inline;
    }

    &.active {
        .tab-name {
            color: #141414;
        }

        .active-img {
            display: inline;
        }

        .inactive-img {
            display: none;
        }
    }
}

.mp-page {
    width: 100%;
    height: 100%;
    position: relative;
}

.page-empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.guid {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(221, 221, 221, 0.9);
}

.close, .mini {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;
    z-index: 888;
    font-size: 30px;
}

.close {
    background-image: url("/assets/icons/close-btn-black.png");
    background-size: contain;
}


</style>