import { render, staticRenderFns } from "./GoodsItem.vue?vue&type=template&id=37bf067c&scoped=true&"
import script from "./GoodsItem.vue?vue&type=script&lang=js&"
export * from "./GoodsItem.vue?vue&type=script&lang=js&"
import style0 from "./GoodsItem.vue?vue&type=style&index=0&id=37bf067c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37bf067c",
  null
  
)

export default component.exports