import { render, staticRenderFns } from "./ImageDeckSimple.vue?vue&type=template&id=810553d6&scoped=true&"
import script from "./ImageDeckSimple.vue?vue&type=script&lang=js&"
export * from "./ImageDeckSimple.vue?vue&type=script&lang=js&"
import style0 from "./ImageDeckSimple.vue?vue&type=style&index=0&id=810553d6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "810553d6",
  null
  
)

export default component.exports