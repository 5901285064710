<template>
    <div>{{ statusText }}</div>
</template>

<script>
import auth from "../../auth";
import request from "../../repo/request";

export default {
    name: "LoginAdmin",
    data() {
        return {
            statusText: '登录中，请稍后...'
        }
    },
    mounted() {
        
        this.checkToken()
        
    },
    methods: {
        checkToken() {
            
            let token = this.$route.query.token
            let appId = this.$route.query.app_id
            
            request.post('/collocation/auth/check', {token}, (data) => {
                
                if (data.code !== 0) {
                    this.statusText = '登录失败，请联系管理员.'
                    return
                }
                
                auth.remember(token, data.data.info)
                
                auth.appId(appId)
                
                this.$router.push('/login');
            });
        }
    }
}
</script>

<style scoped>

</style>