<template>
    <div>
        <div class="drawer">
            
            <transition name="fadeIn">
                <div v-if="show" class="drawer-screen" @click="close"></div>
            </transition>
            
            
            <transition name="slideInRight">
                <div v-if="show" class="drawer-content" :style="{'width':width ? width : '520px'} ">
                    <button class="close-btn iconfont pc-guanbi" @click="close"></button>
                    <slot/>
                </div>
            </transition>
            
        </div>
    </div>
</template>

<script>
export default {
    name: "Drawer",
    model: {
        prop: 'active',
        event: 'change',
    },
    props: {
        active: Boolean,
        width: String
    },
    watch: {
        active() {
            if (this.active === this.show) return
            this.active ? this.open() : this.close()
        }
    },
    mounted() {
        this.show = this.active
        if (this.show) this.open()
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {
        open() {
            this.show = true
        },
        close() {
            this.show = false
            this.$emit('change', false)
        }
    }
}
</script>

<style lang="less" scoped>
.drawer {
    position: relative;
    z-index: 9999;
}

.drawer-content, .drawer-screen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
}

.drawer-screen {
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
}

.drawer-content {
    width: 520px;
    background-color: white;
}

.close-btn {
    background-color: transparent;
    border: none;
    font-size: 15px;
    position: absolute;
    right: 32px;
    top: 25px;
}


</style>