<template>
    <div class="video-list">
        <div class="content">
            <div class="header">
                <div class="title">选择视频</div>
                <div class="close cursor" @click="close">
                    <img src="/assets/images/video/upload-close.png">
                </div>
            </div>
            <a-spin type="loading" :spinning="spinning">
                <div class="list">
                    <div class="video-item cursor" v-for="item in videoList" :key="item.id" @click="select(item)">

                        <div class="select">
                            <img src="/assets/images/video/select.png" v-if="item.id == video.id">
                            <div v-else></div>
                        </div>

                        <div class="video-thumb">
                            <img :src="item.thumb">
                        </div>

                        <div class="video-info">

                            <div class="name">{{ item.name }}</div>

                            <div class="info">
                                <div class="info-row">
                                    <div class="info-label">视频时长</div>
                                    <div class="info-num">{{ videoTime(item) }}</div>
                                </div>
                                <div class="info-row">
                                    <div class="info-label">视频大小</div>
                                    <div class="info-num">{{ videoSize(item) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a-spin>
            <div class="footer">
                <div class="pagination">
                    <a-pagination :current="pager.current" :total="pager.total" :page-size="pager.pageSize"
                        @change="pageChange" />

                    <div class="total">共{{ pager.total }}条数据</div>
                </div>

                <div class="btn" @click="confirm">确定</div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/repo/api';
import moment from "moment/moment";
export default {
    name: "VideoList",
    data() {
        return {
            videoList: [],
            spinning: false,
            video: {},
            pager: {
                total: 1,
                current: 1,
                pageSize: 15,
            },
        }
    },
    mounted() {
        this.getVideoList()
    },
    methods: {
        confirm(){
            if (this.video.id) {
                this.$emit('confirm',this.video);
            }
        },
        select(item){
            if (this.video.id == item.id) {
                this.video = {}
            } else {
                this.video  = item;
            }
        },
        videoSize(item) {
            let size = Number(item.size) / 1024 / 1024;
            return size.toFixed(2) + 'M'
        },
        videoTime(item) {
            let seconds = Number(item.info.duration);
            var duration = moment.duration(Number(seconds), 'seconds');
            var h = duration.hours();
            var m = duration.minutes();
            var s = duration.seconds();
            return (h < 10 ? `0${h}` : h) + ':' + (m < 10 ? `0${m}` : m) + ':' + (s < 10 ? `0${s}` : s)
        },
        close() {
            this.$emit('close');
        },
        pageChange(e) {
            this.pager.current = e;
            this.getVideoList();
        },
        getVideoList() {
            this.spinning = true;
            api.get('/ast-space/videos', { page: this.pager.current }, res => {
                this.spinning = false;
                if (res.code == 0) {
                    this.videoList = res.data.data;
                    this.pager.total = res.data.total;
                    this.pager.pageSize = res.data.per_page;
                    this.pager.current = res.data.current_page;
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.video-list {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    .content {
        width: 1000px;
        height: 614px;
        background: #FFFFFF;
        border-radius: 2px;

        .header {
            width: 100%;
            height: 62px;
            padding: 0 32px;
            position: relative;

            .title {
                color: #333333;
                font-size: 16px;
                font-weight: bold;
                line-height: 62px;
            }

            .close {
                width: 56px;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                right: 0;

                img {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .list {
            width: 100%;
            height: 480px;
            border: 1px solid #EEEEEE;
            padding: 0 32px;
            overflow-y: scroll;

            .video-item {
                width: 444px;
                height: 128px;
                background: #FFFFFF;
                border: 1px solid #EEEEEE;
                margin-top: 24px;
                margin-right: 24px;
                display: inline-flex;
                align-items: center;
                padding: 16px;
                position: relative;

                &:nth-child(2n) {
                    margin-right: 0;
                }

                .select {
                    width: 56px;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 0;
                    right: 0;

                    img {
                        width: 24px;
                        height: 24px;
                    }

                    div {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        border: 2px solid rgba(0, 0, 0, 0.4);
                    }
                }

                .video-thumb {
                    width: 96px;
                    height: 96px;
                    margin-right: 16px;

                    img {
                        width: 96px;
                        height: 96px;
                        object-fit: cover;
                        border: 1px solid #EEEEEE;
                    }
                }

                .video-info {
                    height: 96px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .name {
                        color: #333333;
                        font-size: 14px;
                        font-weight: bold;
                        line-height: 20px;
                    }

                    .info {
                        display: flex;

                        .info-row {
                            margin-right: 32px;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;

                            .info-label {
                                color: #999999;
                                font-size: 14px;
                                line-height: 20px;
                            }

                            .info-num {
                                color: #333333;
                                font-size: 16px;
                                font-weight: bold;
                                line-height: 22px;
                                margin-top: 8px;
                            }
                        }
                    }
                }
            }
        }

        .footer {
            width: 100%;
            height: 72px;
            padding: 0 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .pagination {
                display: flex;
                align-items: center;

                .total {
                    color: #333333;
                    font-size: 14px;
                    margin-left: 16px;
                }
            }
        }
    }
}
</style>