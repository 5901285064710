<template>
    <div>
        <a-tabs @change="tabChange">
            <a-tab-pane key="custom" tab="自定义首页">
                <div class="tab-summary">
                    说明：该页面可完全自由设计，呈现你想呈现的长图信息，页面名称也可根据呈现的信息自由命名；为提升用户页面访问速度，建议该页长图压缩至2M以内
                </div>
                <div class="at-input-group">
                    <span class="label">页面启用：</span>

                    <a-switch checked-children="开"
                              v-model="item.page_custom_index_enabled"
                              un-checked-children="关"
                              :disabled=" isDisabled('page_custom_index_enabled') "
                              @change="sumEnabledPage"
                    />
                    <div v-if="isDisabled('page_custom_index_enabled')" class="ml-4">
                        <span>小程序底部导航栏空间限制，仅可开启4个版块，请关闭部分版块再尝试开启本功能版块</span>
                    </div>
                </div>
                <div class="at-input-group">
                    <span class="label">自定义首页名称：</span>
                    <at-input @change="triggerPreview" v-model="item.page_custom_index_name" width="300px"></at-input>
                </div>
                <div class="at-input-group">
                    <span class="label">自定义首页内容图片：</span>
                    <upload-image style="max-width: 150px" v-model="item.page_custom_index_image"
                                  @change="triggerPreview"/>
                    <div class="pay-apply-tips">
                        图片格式必须为：png,bmp,jpeg,jpg；不可大于2M；
                        建议使用png格式图片，以保持最佳效果；
                        建议图片宽度为980px
                    </div>
                </div>

            </a-tab-pane>

            <a-tab-pane key="card" tab="名片">
                <div class="at-input-group">
                    <span class="label">页面启用：</span>
                    <a-switch checked-children="开"
                              v-model="item.page_card_enabled"
                              un-checked-children="关"
                              :disabled=" isDisabled('page_card_enabled') "
                              @change="sumEnabledPage"
                    />
                    <div v-if="isDisabled('page_card_enabled')" class="ml-4">
                        <span>小程序底部导航栏空间限制，仅可开启4个版块，请关闭部分版块再尝试开启本功能版块</span>
                    </div>
                </div>
                <div class="at-input-group">
                    <span class="label">AI检测机器人：</span>
                    <a-switch checked-children="开"
                              v-model="item.index_robot"
                              un-checked-children="关"
                              @change="triggerPreview"
                    />
                </div>
                <div class="at-input-group">

                    <pay-box title="名片资料" style="width: 600px">
                        <table class="at-table addr-table">
                            <tr>
                                <th>头像</th>
                                <td>
                                    <div class="pdf-uploader-container">
                                        <div class="pdf-uploader">
                                            <upload-image v-model=" adminInfo.avatar " @change=" triggerPreview  "/>
                                        </div>
                                    </div>

                                    <div class="tips">建议尺寸，300*300，图片最大为2M</div>
                                </td>
                            </tr>
                            <tr>
                                <th>名称</th>
                                <td>
                                    <at-input v-model=" adminInfo.name " class="full-with" @change=" triggerPreview  "/>
                                </td>
                            </tr>
                            <tr>
                                <th>简介</th>
                                <td>
                                    <a-textarea v-model=" adminInfo.summary " class="at-textarea" rows="4"
                                                @change=" triggerPreview  "></a-textarea>
                                </td>
                            </tr>
                            <tr>
                                <th>电话号码</th>
                                <td>
                                    <at-input v-model=" adminInfo.phone " class="full-with"
                                              @change=" triggerPreview  "/>
                                </td>
                            </tr>
                            <tr>
                                <th>邮箱</th>
                                <td>
                                    <at-input v-model=" adminInfo.email " class="full-with"
                                              @change=" triggerPreview  "/>
                                </td>
                            </tr>
                            <tr>
                                <th>地址</th>
                                <td>
                                    <at-input class="full-with" v-model="adminInfo.address"
                                              @change=" triggerPreview  "/>
                                </td>
                            </tr>
                            <tr>
                                <th>首页图</th>
                                <td>
                                    <div class="pdf-uploader-container">
                                        <div class="pdf-uploader">
                                            <upload-image v-model=" adminInfo.background"
                                                          @change=" triggerPreview  "/>
                                        </div>
                                    </div>

                                    <div class="tips">建议尺寸，414*985，图片最大为2M</div>
                                </td>
                            </tr>
                            <tr>
                                <th>详情图</th>
                                <td>
                                    <div class="pdf-uploader-container">
                                        <div class="pdf-uploader">
                                            <upload-image v-model=" adminInfo.detail"
                                                          @change=" triggerPreview  " multiple/>
                                        </div>
                                    </div>

                                    <div class="tips">建议尺寸，414*985，图片最大为2M</div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                </th>
                                <td>
                                    <service-image class="mb-4"><a>私域客服设置 》》》</a></service-image>

                                    <wechat-video @change="triggerPreviewCs">
                                        <a>视频号显示设置 》》》</a>
                                    </wechat-video>
                                </td>
                            </tr>

                        </table>
                    </pay-box>

                </div>


            </a-tab-pane>
            <a-tab-pane key="ai" tab="AI检测">
                <div class="tab-summary">
                    说明：AI检测是用户自助拍照进行检测，付费主理人后自动解锁报告，无须人工服务部分，适合初期引流及筛选客户
                </div>
                <div class="at-input-group">
                    <span class="label">页面启用：</span>
                    <a-switch checked-children="开"
                              v-model="item.page_ai_enabled"
                              un-checked-children="关"
                              :disabled=" isDisabled('page_ai_enabled') "
                              @change="sumEnabledPage"
                    />
                    <div v-if="isDisabled('page_ai_enabled')" class="ml-4">
                        <span>小程序底部导航栏空间限制，仅可开启4个版块，请关闭部分版块再尝试开启本功能版块</span>
                    </div>
                </div>
                <div class="at-input-group">
                    <span class="label">
                        <span>AI检测支付价格：</span>
                        <br>
                        <span>(实付金额)</span>
                    </span>
                    <at-input v-model="item.detect_ai_pay_price" width="300px"></at-input>
                </div>
                <div class="at-input-group">
                    <span class="label">
                        <span>AI检测划线价格：</span>
                        <br>
                        <span>(显示原价)</span>
                    </span>
                    <at-input v-model="item.detect_ai_line_price" width="300px"></at-input>
                </div>
                <button class="btn" style="margin-bottom: 10px;" @click="handleEditPoster">
                    分享海报设置
                </button>
            </a-tab-pane>
            <a-tab-pane key="detect" tab="风格">
                <div class="tab-summary">
                    说明：风格诊断产品是，AI数据采集检测+用户档案、生活照上传+主理人人工复诊判断修正，必须要发起实体报告印刷后，才会生成一份电子报告副本。
                    适合将个人形象风格诊断产品做成爆款盈利产品。
                </div>
                <div class="at-input-group">
                    <span class="label">页面启用：</span>
                    <a-switch checked-children="开"
                              v-model="item.page_detect_enabled"
                              un-checked-children="关"
                              :disabled=" isDisabled('page_detect_enabled') "
                              @change="sumEnabledPage"
                    />
                    <div v-if="isDisabled('page_detect_enabled')" class="ml-4">
                        <span>小程序底部导航栏空间限制，仅可开启4个版块，请关闭部分版块再尝试开启本功能版块</span>
                    </div>
                </div>

                <div class="mb-4">
                    <a target="_blank" href="/ast/paper/basic">实体打印报告设置 》》》</a>
                </div>
                <button class="btn" style="margin-bottom: 10px;" @click="handleEditPoster">
                    分享海报设置
                </button>
            </a-tab-pane>
            <a-tab-pane key="service" tab="服务">
                <div class="tab-summary">
                    说明：服务版块，可按课程、服务、活动三种类型（子版块可部分开启）发布商品及详情介绍，可设置价格及用户报名是否需要在线支付等
                </div>
                <div class="at-input-group">
                    <span class="label">页面启用：</span>
                    <a-switch checked-children="开"
                              v-model="item.page_service_enabled"
                              un-checked-children="关"
                              :disabled=" isDisabled('page_service_enabled') "
                              @change="sumEnabledPage"
                    />
                    <div v-if="isDisabled('page_service_enabled')" class="ml-4">
                        <span>小程序底部导航栏空间限制，仅可开启4个版块，请关闭部分版块再尝试开启本功能版块</span>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane key="shop" tab="商城">
                <div class="tab-summary">
                    说明：自营商店商品展示页面，适合以销售商品为主使用
                </div>
                <div class="at-input-group">
                    <span class="label">页面启用：</span>
                    <a-switch checked-children="开"
                              v-model="item.page_shop_enabled"
                              un-checked-children="关"
                              :disabled=" isDisabled('page_shop_enabled') "
                              @change="sumEnabledPage"
                    />
                    <div v-if="isDisabled('page_shop_enabled')" class="ml-4">
                        <span>小程序底部导航栏空间限制，仅可开启4个版块，请关闭部分版块再尝试开启本功能版块</span>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane key="scheme" tab="搭配">
                <div class="tab-summary">
                    说明：搭配板块是主理人基于自己小商店商品（自售），结合电商拼图商品，进行提前组合搭配并生成OOTD搭配方案。搭配方案会绑定适合人群风格类型，用户可在手机端进行闲逛浏览及跳转购买。
                    建议销售实体服装的主理人开启使用
                </div>
                <div class="at-input-group">
                    <span class="label">页面启用：</span>
                    <a-switch checked-children="开"
                              v-model="item.page_scheme_enabled"
                              un-checked-children="关"
                              :disabled=" isDisabled('page_scheme_enabled') "
                              @change="sumEnabledPage"
                    />
                    <div v-if="isDisabled('page_scheme_enabled')" class="ml-4">
                        <span>小程序底部导航栏空间限制，仅可开启4个版块，请关闭部分版块再尝试开启本功能版块</span>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane key="cases" tab="案例">
                <div class="tab-summary">
                    说明：案例&分享版块用户可在自由发布，主理人后台审核后可显示在该版块和关联的产品详情页下。用大量真实客户案例见证，增强用户信赖感、激发用户购买欲。
                </div>
                <div class="at-input-group">
                    <span class="label">页面启用：</span>
                    <a-switch checked-children="开"
                              v-model="item.page_cases_enabled"
                              un-checked-children="关"
                              :disabled=" isDisabled('page_cases_enabled') "
                              @change="sumEnabledPage"
                    />
                    <div v-if="isDisabled('page_cases_enabled')" class="ml-4">
                        <span>小程序底部导航栏空间限制，仅可开启4个版块，请关闭部分版块再尝试开启本功能版块</span>
                    </div>
                </div>
            </a-tab-pane>
        </a-tabs>

        <div class="mb-2">
            <button @click="saveChange" class="btn">保存</button>
        </div>
        <small>因为小程序限制，最多启用4个页面</small>

        <PosterModal
                v-model="showPosterModal"
                :defaultBackground="posterItem.background"
                :defaultQrcode="posterItem.qrcode"
                @submit="handlePosterSubmit"
                @clean="handlePosterClean"
        ></PosterModal>
    </div>
</template>

<script>
import AtInput from "@/components/AtInput.vue";
import UploadImage from "@/components/UploadImage.vue";
import PayBox from "@/views/AST/Pay/Step/PayBox.vue";
import WechatVideo from "@/views/AST/Xcx/Items/WechatVideo.vue";
import ServiceImage from "@/views/AST/Xcx/Items/ServiceImage.vue";
import PosterModal from "@/components/PosterModal.vue"

export default {
    name: "MTabs",
    components: {
        ServiceImage,
        WechatVideo,
        PayBox,
        UploadImage,
        AtInput,
        PosterModal,
    },
    props: {
        config: Object
    },
    watch: {
        config() {
            this.init()
        }
    },
    mounted() {
        this.init()
    },
    data() {
        return {
            item: {
                page_card_enabled: "",
                index_robot: "",
                page_ai_enabled: "",
                detect_ai_pay_price: "",
                detect_ai_line_price: "",
                page_detect_enabled: "",
                page_service_enabled: "",
                page_shop_enabled: "",
                page_scheme_enabled: "",
                page_custom_index_enabled: false,
                page_custom_index_name: "",
                page_custom_index_image: "",
                page_cases_enabled: "",
                page_ai_poster: '',
                page_ai_poster_frame: null,
                page_detect_poster: '',
                page_detect_poster_frame: null,
            },
            tabKey: "",
            adminInfo: {},
            enabledPage: undefined,
            showPosterModal: false,
            posterItem: {
                background: undefined,
                qrcode: undefined,
            },
        }
    },
    methods: {
        init() {
            for (let key in this.item) {
                this.item[key] = this.config[key]
            }

            this.adminInfo = this.config.master_info || {}

            this.sumEnabledPage()
        },
        tabChange(val) {
            this.tabKey = val
            this.$emit('tabChange', val)
        },
        sumEnabledPage() {
            setTimeout(() => {
                const list = [
                    'page_custom_index_enabled',
                    'page_card_enabled',
                    'page_ai_enabled',
                    'page_detect_enabled',
                    'page_service_enabled',
                    'page_scheme_enabled',
                    'page_cases_enabled'
                ];

                let num = 0

                list.forEach(key => {
                    if (this.item[key]) num++
                })

                if (this.enabledPage !== undefined) {
                    this.triggerPreview()
                }

                this.enabledPage = num

            }, 50)
        },
        isDisabled(key) {
            if (this.enabledPage === undefined) return false;

            return (!this.item[key]) && this.enabledPage >= 4;
        },
        triggerPreviewCs(item) {
            this.$emit('preview', 'pages', item);
        },
        triggerPreview() {
            this.$emit('preview', 'pages', this.item)
        },
        saveChange() {
            this.$emit('change', 'pages', this.item)
            this.$emit('change', 'master-info', this.adminInfo)
        },
        handleEditPoster() {
            this.showPosterModal = true
            switch (this.tabKey) {
                case "ai": {
                    if (this.item.page_ai_poster) {
                        this.posterItem.background = {
                            imageUrl: this.item.page_ai_poster ?? '',
                            w: this.item.page_ai_poster_frame.background.w ?? 0,
                            h: this.item.page_ai_poster_frame.background.h ?? 0,
                        }
                        this.posterItem.qrcode = {
                            w: this.item.page_ai_poster_frame.qrcode.w ?? 0,
                            h: this.item.page_ai_poster_frame.qrcode.h ?? 0,
                            l: this.item.page_ai_poster_frame.qrcode.l ?? 0,
                            t: this.item.page_ai_poster_frame.qrcode.t ?? 0,
                        }
                    } else {
                        this.posterItem.background = null
                        this.posterItem.qrcode = null
                    }
                }
                    break
                case "detect": {
                    if (this.item.page_detect_poster) {
                        this.posterItem.background = {
                            imageUrl: this.item.page_detect_poster ?? '',
                            w: this.item.page_detect_poster_frame.background.w ?? 0,
                            h: this.item.page_detect_poster_frame.background.h ?? 0,
                        }
                        this.posterItem.qrcode = {
                            w: this.item.page_detect_poster_frame.qrcode.w ?? 0,
                            h: this.item.page_detect_poster_frame.qrcode.h ?? 0,
                            l: this.item.page_detect_poster_frame.qrcode.l ?? 0,
                            t: this.item.page_detect_poster_frame.qrcode.t ?? 0,
                        }
                    } else {
                        this.posterItem.background = null
                        this.posterItem.qrcode = null
                    }
                }
                    break
            }
        },
        handlePosterSubmit({background, qrcode}) {
            const poster = background.imageUrl
            const frame = {
                background: {
                    w: background.w,
                    h: background.h,
                },
                qrcode: {
                    w: qrcode.w,
                    h: qrcode.h,
                    l: qrcode.l,
                    t: qrcode.t,
                },
            }
            switch (this.tabKey) {
                case "ai": {
                    this.item.page_ai_poster = poster
                    this.item.page_ai_poster_frame = frame
                }
                    break
                case "detect": {
                    this.item.page_detect_poster = poster
                    this.item.page_detect_poster_frame = frame
                }
                    break
                default: {
                    console.error("错误的tabKey")
                    return
                }
            }
            this.showPosterModal = false
            this.saveChange()
        },
        handlePosterClean() {
            switch (this.tabKey) {
                case "ai": {
                    this.item.page_ai_poster = ""
                    this.item.page_ai_poster_frame = null
                }
                    break
                case "detect": {
                    this.item.page_detect_poster = ""
                    this.item.page_detect_poster_frame = null
                }
                    break
                default: {
                    console.error("错误的tabKey")
                    return
                }
            }
            this.showPosterModal = false
            this.saveChange()
        },
    }
}
</script>

<style scoped lang="less">
.pay-apply-tips {
    width: 300px;
    padding-left: 16px;
}

.tab-summary {
    margin-bottom: 16px;
    width: 750px;
}

.pdf-uploader-container {
    display: flex;
    justify-content: flex-start;
}


.addr-input {
    width: 100%;
    margin-bottom: 5px;
}

.addr-table {
    width: 100%;
    border: none;

    th {
        background-color: transparent;
        width: 90px;
        font-size: 14px;
        color: #333333;
        line-height: 16px;
        font-weight: normal;

        text-align: right;
    }

    td {
        text-align: left;
    }

    tr, th, td {
        border: none;
    }
}

</style>