<template>
    <div class="create-courses">
        <div class="page-title">新增课程</div>
        <div class="container">
            <div class="head">
                <div class="label">基础内容</div>
                <div class="line"></div>
            </div>
            <div class="content-box">
                
                <div class="row">
                    <div class="label">
                        <span class="red">*</span>
                        <span>课程标题</span>
                    </div>
                    <div class="input-box">
                        <input v-model="params.title" type="text" placeholder="请输入不超过20字的标题" maxlength="20">
                        <div class="num">{{ params.title.length }}/20</div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="label">
                        <span class="white">占</span>
                        <span class="red">*</span>
                        <span>副标题</span>
                    </div>
                    <div class="input-box">
                        <input v-model="params.sub_title" type="text" placeholder="请输入不超过30字的副标题"
                               maxlength="30">
                        <div class="num">{{ params.sub_title.length }}/30</div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="label">
                        <span class="red">*</span>
                        <span>课程封面</span>
                    </div>
                    <div>
                        <div class="content-img">
                            <upload-image v-model="params.thumb"/>
                        </div>
                        <div class="thumb-tips">
                            图片格式必须为:png,jpeg,jpg;不可大于1M；<br>
                            建议使用png格式图片，以保持最佳效果;建议图片尺寸为600px*600px；
                        </div>
                    </div>
                </div>
                
                <div class="form-row">
                    <div class="form-item">
                        <div class="form-label">
                            <div class="label">课程时间</div>
                            <div class="tips">（选填）</div>
                        </div>
                        <div class="form">
                            <a-date-picker v-model="params.start" class="date-picker" format="YYYY-MM-DD"
                                           placeholder="选择时间"/>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="form-label">
                            <div class="label">排序序号</div>
                        </div>
                        <div class="form">
                            <div class="input">
                                <input v-model="params.sort" type="text">
                            </div>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="form-label">
                            <div class="label">授课方式</div>
                        </div>
                        <div class="form">
                            <a-select v-model="params.type" class="at-select">
                                <a-select-option value="online">线上授课</a-select-option>
                                <a-select-option value="offline">线下授课</a-select-option>
                                <a-select-option value="video" v-if="$app.modules.course_video">在线视频
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                </div>
                
                <div class="form-row">
                    <div class="form-item">
                        <div class="form-label">
                            <div class="label">课程价格</div>
                            <div class="tips">（选填）</div>
                        </div>
                        <div class="form">
                            <div class="input">
                                <input v-model="params.price" type="text">
                            </div>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="form-label">
                            <div class="label">报名开启<br>支付</div>
                        </div>
                        <div class="form">
                            <a-select v-model="params.pay_enabled" @change="params.pay_amount = '0.00'"
                                      class="at-select">
                                <a-select-option :value="1">开启</a-select-option>
                                <a-select-option :value="0">关闭</a-select-option>
                            </a-select>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="form-label">
                            <div class="label">金额支付</div>
                        </div>
                        <div class="form">
                            <div class="input">
                                <input v-model="params.pay_amount" :disabled="!params.pay_enabled" type="number">
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="form-row">
                    <div class="form-item">
                        <div class="form-label">
                            <div class="label">地点</div>
                            <div class="tips">（选填）</div>
                        </div>
                        <div class="form">
                            <div class="input">
                                <input v-model="params.address" type="text">
                            </div>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="form-label">
                            <div class="label">开启分销</div>
                        </div>
                        <div class="form">
                            <a-select v-model="params.share_enabled" :disabled="!shareEnabled" class="at-select">
                                <a-select-option :value="1">开启</a-select-option>
                                <a-select-option :value="0">关闭</a-select-option>
                            </a-select>
                        </div>
                    </div>
                    <div class="form-item" v-if="shareEnabled">
                        <div class="form-label">
                            <div class="label">分销比例</div>
                        </div>
                        <div class="form">
                            <div class="input">
                                <input v-model="params.share_scale" :disabled="!params.share_enabled" type="text">
                                <div class="scale-unit">%</div>
                            </div>
                        </div>
                    </div>
                    <div v-else>如需开通分销功能，请联系客服进行机构认证！</div>
                </div>
                
                <div class="row">
                    <div class="label">
                        <span class="red">*</span>
                        <span>课程详情</span>
                    </div>
                    <div class="input-box">
                        <textarea v-model="params.summary" placeholder="请输入课程内容相关说明"></textarea>
                    </div>
                </div>
                
                <div class="row">
                    <div class="label">
                        <span class="white">*占占占占</span>
                        <span></span>
                    </div>
                    <div>
                        <div class="content-img">
                            <upload-image v-model="params.content_images" multiple/>
                        </div>
                        <div class="thumb-tips">
                            图片格式必须为:png,bmp,jpeg,jpg;不可大于2M;<br>
                            建议使用png格式图片，以保持最佳效果;建议图片宽度为980px
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" v-if="params.type == 'video'">
            <div class="head">
                <div class="label">课程章节</div>
                <div class="line"></div>
            </div>
            <div class="content-box">
                <div class="contents">
                    <div class="content-item" v-for="(item, i) in list" :key="i">
                        <div class="parent">
                            <div class="label">
                                <span class="red">*</span>
                                <span>{{ (i + 1) + '.第' + chCode[i] + '章' }}</span>
                            </div>
                            <div class="input">
                                <input v-model="item.title" type="text">
                                <img class="title" src="/assets/images/video/contents-title.png">
                            </div>
                        </div>
                        <div class="child" v-for="(child, k) in item.children" :key="engCode[k]">
                            <div class="label">
                                <span class="white">*</span>
                                <span>{{ engCode[k] + '.节标题' }}</span>
                            </div>
                            <div class="right">
                                <div class="input">
                                    <input v-model="child.title" type="text">
                                    <div class="util">
                                        <div class="add cursor" @click="addChildHandle(i, k)">添加小节</div>
                                        <div class="line"></div>
                                        <div class="del cursor" @click="delChildHandle(i, k)">删除小节</div>
                                    </div>
                                </div>
                                <div class="summary">
                                    <a-textarea class="at-textarea" v-model="child.summary" :maxLength="1000"
                                                placeholder="可输入小节内容说明"></a-textarea>
                                </div>
                                <div class="video-box">
                                    <div class="video" v-if="child.file" @click="playVideo(child.file)">
                                        <img class="thumb" :src="child.file.thumb">
                                        <div class="video-play-btn"></div>
                                        <div class="mask">
                                            <div class="icon" @click.stop="delVideo(child)">
                                                <img src="/assets/images/video/del-select.png">
                                            </div>
                                            <div class="size">{{ videoSize(child.file) }}</div>
                                            <div class="name">{{ child.file.name }}</div>
                                        </div>
                                    </div>
                                    <div class="btn-row">
                                        <div class="btn-item cursor" @click="openList(child)">
                                            <img src="/assets/images/video/btn-icon2.png">
                                            <span>选择视频</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="add-parent cursor" @click="addParentHandle">
                    <div>点击继续添加章节</div>
                    <img src="/assets/icons/new-question-finger.png">
                </div>
            </div>
        </div>
        <div class="container">
            <div class="head">
                <div class="label">课程营销</div>
                <div class="line"></div>
            </div>
            <div class="content-box">
                <div class="form-row">
                    <div class="form-item">
                        <div class="form-label">
                            <div class="label">首次购买<br>优惠价格</div>
                        </div>
                        <div class="form">
                            <div class="input">
                                <input v-model="params.first_buy_amount" type="text">
                            </div>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="form-label">
                            <div class="label">首次购买优惠限时</div>
                            <div class="tips">(分钟)</div>
                        </div>
                        <div class="form">
                            <div class="input">
                                <input v-model="params.first_buy_time" type="text">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="head">
                <div class="label">购买后功能</div>
                <div class="line"></div>
            </div>
            <div class="content-box">
                <div class="form-row">
                    <div class="form-item">
                        <div class="form-label">
                            <div class="label">赠送<br>衣橱会员</div>
                        </div>
                        <div class="form">
                            <a-select v-model="params.give_wd_vip" class="at-select">
                                <a-select-option :value="1">赠送</a-select-option>
                                <a-select-option :value="0">不赠送</a-select-option>
                            </a-select>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="form-label">
                            <div class="label">会员<br>有效期限</div>
                        </div>
                        <div class="form">
                            <a-select v-model="params.give_member_level_days" class="at-select">
                                <template v-for="(md) in memberLevelDays">
                                    <a-select-option :value="md.day" :key="md.day" v-if="true">
                                        <span>{{ md.name }}</span>
                                    </a-select-option>
                                </template>
                            </a-select>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-item">
                        <div class="form-label">
                            <div class="label">等级变更</div>
                        </div>
                        <div class="form">
                            <a-select v-model="params.give_member_level" class="at-select">
                                <a-select-option :value="0">不变更</a-select-option>
                                <template v-for="(level, key) in memberLevels">
                                    <a-select-option :value="level.id" :key="key" v-if="true">
                                        <span>{{ level.name }}</span>
                                    </a-select-option>
                                </template>
                            </a-select>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="form-label">
                            <div class="label">给予权限</div>
                        </div>
                        <div class="form">
                            <a-select v-model="params.give_permissions" class="at-select" placeholder="不给予权限">
                                <a-select-option :value="0">不给予权限</a-select-option>
                                <template v-for="(name, key) in memberPermissions">
                                    <a-select-option :value="key" :key="key" v-if="true">{{ name }}</a-select-option>
                                </template>
                            </a-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="btn-box">
            <div class="btn cursor" @click="submit">{{ params.id ? '保存' : '创建' }}</div>
            <template v-if="params.id">
                <div class="btn btn-white cursor">
                    <span v-if="params.status == 0" @click="stock(1)">上架</span>
                    <span v-else @click="stock(0)">下架</span>
                </div>
                <div class="btn btn-white cursor" @click="deleteItem">删除</div>
            </template>
        </div>
        
        <video-list v-if="showList" @confirm="videoConfirm" @close="showList = false"></video-list>
        
        <a-modal v-model="showVideoModal" :footer="null" width="800px" destroyOnClose>
            <template v-if="videoInfo">
                
                <div slot="title">{{ videoInfo.name }}</div>
                
                <video style="width: 100%" :src="videoInfo.url" autoplay controls></video>
            
            </template>
        </a-modal>
    
    </div>
</template>

<script>
import api from '@/repo/api';
import VideoList from './com/VideoList.vue';
import UploadImage from "@/components/UploadImage.vue";

export default {
    components: {VideoList, UploadImage},
    data() {
        return {
            routeMatch: "/ast/serve/cla",
            showList: false,
            memberLevelDays: [
                {name: "7天", day: 7},
                {name: "半个月 (15天)", day: 15},
                {name: "1个月 (30天)", day: 30},
                {name: "3个月 (90天)", day: 90},
                {name: "半年 (180天)", day: 180},
                {name: "1年 (365天)", day: 365},
                {name: "长期", day: -1}
            ],
            params: {
                group: "courses",
                title: '',
                sub_title: '',
                thumb: '',
                start: '',
                sort: '',
                type: '',
                pay_enabled: 0,
                pay_amount: '0.00',
                price: '',
                share_enabled: 0,
                share_scale: '',
                address: '',
                summary: '',
                content_images: [],
                first_buy_amount: '',
                first_buy_time: '',
                give_wd_vip: '',
                give_member_level: 0,
                give_member_level_days: '',
                give_permissions: [],
            },
            videoInfo: null,
            showVideoModal: false,
            shareEnabled: 0,
            memberLevels: [],
            memberPermissions: {},
            nos: 'ABCDEFGHIJKLMNOPQRSTUVWXZY',
            engCode: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'],
            chCode: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九', '二十'],
            list: [
                {
                    title: '',
                    children: [
                        {
                            title: '',
                        }
                    ]
                }
            ],
            child: {}
        }
    },
    mounted() {
        let query = this.$route.query;
        console.log('app', this.$app);
        this.shareEnabled = this.$app['course_share'];
        
        if (query.id) {
            this.getDetail(query.id);
        }
        this.getPermissionsAndLevels();
    },
    methods: {
        deleteItem() {
            this.confirm('确定要删除？').then(() => {
                
                api.post('/course/delete', {id: this.params.id}, () => {
                    this.$router.replace({
                        path: '/ast/serve/cla'
                    })
                })
                
            })
        },
        playVideo(item) {
            this.videoInfo = item;
            this.showVideoModal = true;
        },
        confirm(title) {
            return new Promise((resolve) => {
                this.$confirm({
                    title: title,
                    content: '此操作不可逆。',
                    onOk() {
                        resolve()
                    },
                });
            })
        },
        stock(status) {
            
            let title = status ? "上架" : '下架';
            
            this.confirm('确定' + title).then(() => {
                
                api.post('/course/stock', {status, id: this.params.id}, () => {
                    
                    this.$router.replace({
                        path: '/ast/serve/cla'
                    })
                    
                })
                
            })
        },
        videoSize(item) {
            let size = Number(item.size) / 1024 / 1024;
            return size.toFixed(2) + 'MB';
        },
        delVideo(child) {
            this.$set(child, 'file', '');
        },
        videoConfirm(item) {
            console.log(item.name);
            this.$set(this.child, 'file', item);
            this.$set(this.child, 'file_id', item.id);
            this.showList = false;
        },
        openList(child) {
            this.child = child;
            this.showList = true;
        },
        addParentHandle() {
            this.list.push({title: '', children: [{title: ''}]});
        },
        addChildHandle(i) {
            console.log(this.list);
            this.list[i].children.push({title: ''});
        },
        delChildHandle(i, k) {
            let _this = this;
            this.$confirm({
                title: "确定要删除这个小节吗？",
                onOk: () => {
                    _this.list[i].children.splice(k, 1);
                    if (_this.list[i].children.length == 0) {
                        _this.list.splice(i, 1);
                    }
                }
            })
        },
        getDetail(id) {
            api.get('/course/detail', {id}, (res) => {
                
                if (res.code == 0) {
                    
                    let item = res.data;
                    
                    delete item.content_img;
                    delete item.created_at;
                    delete item.updated_at;
                    delete item.poster;
                    delete item.poster_frame;
                    delete item.finish;
                    delete item.shares_count;
                    if (item.type == 'video') {
                        this.list = item.videos;
                    }
                    this.params = item;
                }
            })
        },
        getPermissionsAndLevels() {
            api.get('/ast-app/permissions-and-levels', (res) => {
                
                this.memberLevels = res.data.levels;
                this.memberPermissions = res.data.permissions;
                
            })
        },
        submit() {
            if (this.params.type == 'video') {
                this.params.videos = this.list;
            }
            console.log('params', this.params);
            api.post('/course/sync', this.params, (data) => {
                if (data.code == 0) {
                    this.$router.replace({
                        path: '/ast/serve/cla'
                    })
                } else {
                    this.$message.error(data.msg);
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.btn-box {
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
    
    .btn {
        margin-right: 16px;
    }
    
    .btn-white {
        color: #333333;
        border: 1px solid #333;
        background: #FFFFFF;
    }
}

.container {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    margin-top: 32px;
    
    .head {
        width: 100%;
        height: 56px;
        line-height: 56px;
        border-bottom: 1px solid #EEEEEE;
        padding: 0 24px;
        position: relative;
        
        .label {
            color: #000;
            font-size: 16px;
        }
        
        .line {
            width: 2px;
            height: 14px;
            background: #333333;
            position: absolute;
            left: 0;
            top: 21px;
        }
    }
    
    .content-box {
        width: 100%;
        padding: 24px;
        
        .add-parent {
            width: 806px;
            height: 102px;
            background: #F7F7F7;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 32px;
            color: #333333;
            font-size: 14px;
            
            img {
                width: 30px;
                height: 30px;
                margin-top: 4px;
            }
        }
        
        .contents {
            .content-item {
                .parent {
                    display: flex;
                    margin-bottom: 24px;
                }
                
                .child {
                    display: flex;
                    margin-bottom: 24px;
                    
                    .right {
                        .video-box {
                            .video {
                                width: 136px;
                                height: 102px;
                                border: 1px solid #EEEEEE;
                                margin-top: 16px;
                                position: relative;
                                cursor: pointer;
                                
                                .mask {
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    
                                    .name {
                                        width: 100%;
                                        text-align: center;
                                        color: #FFFFFF;
                                        font-size: 12px;
                                        position: absolute;
                                        bottom: 8px;
                                        left: 0;
                                    }
                                    
                                    .size {
                                        height: 14px;
                                        line-height: 14px;
                                        background: #FFFFFF;
                                        border-radius: 2px;
                                        padding: 0 4px;
                                        color: #000000;
                                        font-size: 10px;
                                        position: absolute;
                                        top: 4px;
                                        left: 4px;
                                    }
                                    
                                    .icon {
                                        width: 20px;
                                        height: 20px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        
                                        img {
                                            width: 12px;
                                            height: 12px;
                                        }
                                    }
                                    
                                }
                                
                                .thumb {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                            
                            .btn-row {
                                display: flex;
                                align-items: center;
                                margin-top: 16px;
                                
                                .btn-item {
                                    width: 100px;
                                    height: 28px;
                                    background: #F7F7F7;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin-right: 12px;
                                    
                                    img {
                                        width: 16px;
                                        height: 16px;
                                        margin-right: 4px;
                                    }
                                    
                                    span {
                                        color: #333333;
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
                
                .label {
                    color: #333333;
                    font-size: 14px;
                    margin-top: 10px;
                    margin-right: 16px;
                    
                    .red {
                        color: #FF2323;
                    }
                    
                    .white {
                        color: #FFFFFF;
                    }
                }
                
                .summary {
                    margin-top: 16px;
                    
                    .at-textarea {
                        padding: 16px;
                        min-height: 100px;
                    }
                }
                
                .input {
                    width: 728px;
                    height: 40px;
                    background: #FFFFFF;
                    border: 1px solid #EEEEEE;
                    padding: 10px 16px;
                    display: flex;
                    align-items: center;
                    position: relative;
                    
                    .title {
                        width: 32px;
                        height: 32px;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                    
                    .util {
                        width: 176px;
                        height: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-left: 20px;
                        
                        .line {
                            width: 1px;
                            height: 20px;
                            background: #EEEEEE;
                        }
                        
                        .add {
                            color: #FBB03B;
                            font-size: 14px;
                        }
                        
                        .del {
                            color: #F5606D;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        
        .form-row {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            
            .form-item {
                display: flex;
                align-items: center;
                margin-right: 32px;
                
                .form-label {
                    font-size: 14px;
                    margin-right: 23px;
                    
                    .label {
                        color: #333;
                        text-align: center;
                    }
                    
                    .tips {
                        color: #999;
                    }
                }
                
                .form {
                    width: 168px;
                    height: 40px;
                    
                    .input {
                        width: 168px;
                        height: 40px;
                        background: #FFFFFF;
                        border: 1px solid #EEEEEE;
                        display: flex;
                        align-items: center;
                        position: relative;
                        
                        .scale-unit {
                            line-height: 40px;
                            position: absolute;
                            top: 0;
                            right: 16px;
                        }
                        
                        input {
                            width: 100%;
                            height: 100%;
                            padding: 0 16px;
                        }
                    }
                    
                    .at-select {
                        width: 100%;
                        
                        /deep/ .ant-select-selection--single {
                            height: 40px;
                            border-radius: 0;
                        }
                        
                        /deep/ .ant-select-selection__rendered {
                            line-height: 40px;
                        }
                    }
                    
                    .date-picker {
                        /deep/ .ant-input {
                            height: 40px;
                            border-radius: 0;
                        }
                        
                        /deep/ .ant-calendar-range-picker-separator {
                            line-height: 30px;
                        }
                    }
                }
            }
        }
        
        .row {
            display: flex;
            margin-bottom: 24px;
            
            .label {
                color: #333333;
                font-size: 14px;
                margin-top: 10px;
                margin-right: 16px;
                
                .red {
                    color: #FF2323;
                }
                
                .white {
                    color: #FFFFFF;
                }
            }
            
            .input-box {
                width: 728px;
                background: #FFFFFF;
                border: 1px solid #EEEEEE;
                padding: 10px 16px;
                display: flex;
                align-items: center;
                position: relative;
                
                .num {
                    color: rgba(0, 0, 0, 0.25);
                    font-size: 14px;
                    line-height: 20px;
                    position: absolute;
                    right: 16px;
                    bottom: 10px;
                }
                
                textarea {
                    max-height: 40px;
                    line-height: 20px;
                }
            }
            
            .thumb-tips {
                font-size: 12px;
                font-weight: 400;
                color: #999999;
                line-height: 22px;
            }
        }
    }
}

input,
textarea {
    width: 100%;
    line-height: 20px;
    resize: none;
    border: none;
    outline: none;
    padding: 0;
    color: #333;
    font-size: 14px;
    box-shadow: none !important;
}


.video-play-btn {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.4);
    background-image: url("/assets/icons/video-play-btn.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
</style>