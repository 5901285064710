<template>
    <div id="app">
        <a-config-provider :locale="locale">
            <router-view/>
        </a-config-provider>
    </div>
</template>

<script>
    import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
    import moment from 'moment';
    moment.locale('zh-cn');

    export default {
        name: 'App',
        data() {
            return {
                locale: zhCN
            }
        },
        mounted() {

            this.$message.data = (data) => {

                let status = data.code
                let msg = data.msg

                if (status === 0) {
                    this.$message.success(msg || '操作成功！');
                } else {
                    this.$message.error(msg || '操作失败！');
                }
            }
            
            this.$message.config({
                top: `200px`,
                duration: 3,
                maxCount: 3,
            });
        }

    }
</script>

<style>
    .cursor{
        cursor: pointer;
    }
</style>
