import { render, staticRenderFns } from "./Ban.vue?vue&type=template&id=8f13747a&scoped=true&"
import script from "./Ban.vue?vue&type=script&lang=js&"
export * from "./Ban.vue?vue&type=script&lang=js&"
import style0 from "./Ban.vue?vue&type=style&index=0&id=8f13747a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f13747a",
  null
  
)

export default component.exports