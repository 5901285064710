<template>
    <div>
        <div class="total-info">
            <div class="attr-name">总消费笔数：</div>
            <div class="attr-value big">{{ totalInfo.num }}笔</div>
            <div class="attr-name">订单金额：</div>
            <div class="attr-value">￥{{ totalInfo.amount }}</div>
            <div class="attr-name">支付金额：</div>
            <div class="attr-value">￥{{ totalInfo.paid }}</div>
        </div>

        <div class="filter-info" v-if="filterInfo.filtered">
            <div class="filter-time">
                <span>{{ filterInfo.range[0] }}</span>
                <span class="sp">~</span>
                <span>{{ filterInfo.range[1] }}</span>
            </div>

            <div class="total-info">
                <div class="attr-name">总消费笔数：</div>
                <div class="attr-value big">{{ filterInfo.num }}笔</div>
                <div class="attr-name">订单金额：</div>
                <div class="attr-value">￥{{ filterInfo.amount }}</div>
                <div class="attr-name">支付金额：</div>
                <div class="attr-value">￥{{ filterInfo.paid }}</div>
            </div>
        </div>

        <div>
            <a-table
                    class="stock-table"
                    :data-source="table.data"
                    :loading="table.loading"
                    :pagination="table.pagination"
                    :rowKey="record => record.id || record.key"
                    @change="tableChanged"
                    bordered
            >
                <a-table-column
                        align="center"
                        title="时间"
                        data-index="created_at"
                        :filter-dropdown-visible.sync="filterInfo.show"
                        :filtered="filterInfo.filtered"
                >
                    <template slot-scope="time">
                        {{ $moment(time).format('yyyy-MM-DD') }}
                    </template>

                    <div class="time-filter" slot="filterDropdown">

                        <div class="time-picker">
                            <a-range-picker v-model="filterInfo.range" valueFormat="yyyy-MM-DD">
                                <a-icon slot="suffixIcon" type="clock-circle"/>
                            </a-range-picker>
                        </div>
                        <div class="line"></div>
                        <div class="time-btns">
                            <button @click="timeFiltered">筛选</button>
                            <button @click="timeReset">重置</button>
                        </div>
                    </div>

                </a-table-column>


                <a-table-column
                        title="订单号"
                        data-index="transaction_id"
                        align="center"
                >
                    <div slot-scope="transactionId">

                        <a-tooltip>
                            <template slot="title">
                                <div style="width: 150px">{{ transactionId }}</div>
                            </template>
                            {{ transactionId.substring(16, transactionId.length) }}
                        </a-tooltip>

                    </div>

                </a-table-column>

                <a-table-column title="订单金额" data-index="total_amount" align="center">
                    <template slot-scope="money">
                        ￥{{ money }}
                    </template>
                </a-table-column>

                <a-table-column title="支付金额" data-index="pay_amount" align="center">
                    <template slot-scope="pay_amount">
                        ￥{{ pay_amount }}
                    </template>
                </a-table-column>

                <a-table-column title="购买用户" data-index="payer" align="left">
                    <div slot-scope="val,item">
                        <router-link v-if="item.member" :to="`/ast/members/${item.member.id}`">
                            <div class="member-card">
                                <img :src="item.member.avatar" alt="">
                                <div>
                                    <div class="member-name">{{ item.member.mark_name || item.member.name }}</div>
                                    <div class="member-id">{{ item.member.member_id }}</div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </a-table-column>

                <a-table-column title="上级推荐" data-index="parent" align="left">
                    <div slot-scope="val,item">

                        <template v-if="item.parent">

                            <router-link v-if="item.parent" :to="`/ast/members/${item.parent.id}`">
                                <div class="member-card">
                                    <img :src="item.parent.avatar" alt="">
                                    <div>
                                        <div class="member-name">{{ item.parent.mark_name || item.parent.name }}</div>
                                        <div class="member-id">{{ item.parent.member_id }}</div>
                                    </div>
                                </div>
                            </router-link>

                        </template>
                    </div>
                </a-table-column>


            </a-table>
        </div>
    </div>
</template>

<script>

import api from "@/repo/api";

export default {
    name: "DetailOrder",
    props: {
        memberId: String
    },
    data() {
        return {
            totalInfo: {
                num: 0,
                amount: 0,
                paid: 0
            },
            filterInfo: {
                num: 0,
                amount: 0,
                paid: 0,
                range: [],
                show: false,
                filtered: false
            },
            table: {
                columns: [],
                loading: false,
                data: [],
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 20
                },
            },
            query: {
                page: 1,
                member_id: 0,
                created_start_at: '',
                created_end_at: '',
            },
        }
    },
    mounted() {
        this.getFirstList()
    },
    methods: {
        timeFiltered() {
            this.filterInfo.show = false
            this.getFirstList()
        },
        timeReset() {
            this.filterInfo.range = []
            this.timeFiltered()
        },
        getFirstList() {
            this.query.page = 1
            this.getList()
        },
        getList() {

            this.table.loading = true

            this.query.member_id = this.memberId

            this.query.created_start_at = this.filterInfo.range[0] || ''
            this.query.created_end_at = this.filterInfo.range[1] || ''

            let p = !!(this.query.created_start_at && this.query.created_end_at)

            this.filterInfo.filtered = p

            api.get('/ast-cashier/orders', this.query, (data) => {

                this.table.data = data.data.data
                this.table.pagination.current = data.data.current_page
                this.table.pagination.total = data.data.total
                this.table.pagination.pageSize = data.data.per_page

                let info = p ? this.filterInfo : this.totalInfo

                info.num = data.data.total
                info.paid = data.data.total_paid_amount
                info.amount = data.data.total_order_amount

                this.table.loading = false
            })
        },
        tableChanged(pagination) {
            this.query.page = pagination.current
            this.getList()
        },
    }
}

</script>

<style scoped lang="less">

.total-info, .member-card {
    display: flex;
    align-items: center;
}

.total-info {
    margin-top: 8px;
    margin-bottom: 24px;

    .attr-name {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
    }

    .attr-value {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
        margin-right: 120px;

        &.big {
            font-size: 16px;
            line-height: 16px;
        }
    }
}

.member-card {
    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 5px;
    }

    .member-name {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
    }

    .member-id {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        margin-top: 7px;
    }
}

.time-filter {
    width: 368px;

    .time-picker {
        padding: 24px 16px;
    }

    .line {
        border-top: 1px solid #EEEEEE;
    }

    .time-btns {
        height: 51px;
        display: flex;
        align-items: center;

        button {
            margin-left: 16px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            border: none;
            background-color: transparent;
            cursor: pointer;

            &:first-child {
                color: #333333;
            }
        }
    }
}

.filter-info {
    background: #F5F5F5;
    border-radius: 2px;
    padding: 24px 16px;
    margin-bottom: 24px;

    .sp {
        margin-left: 8px;
        margin-right: 11px;
    }

    .total-info {
        margin-bottom: 0;
        margin-top: 0;
    }

    .filter-time {
        margin-bottom: 16px;
        position: relative;

        &:before {
            content: ' ';
            width: 2px;
            height: 10px;
            background: #333333;
            display: block;
            position: absolute;
            left: -16px;
            top: 5px;
        }
    }
}

</style>