<template>
    <div class="goods-content">
        <div class="content-header">
            <div class="header-title">
                <template v-if="type === 'collect'">
                    <img height="64" src="/assets/images/title.sc.png" alt="">
                </template>
                <template v-else>
                    <img height="64" src="/assets/images/title.gd.png" alt="">
                </template>
            </div>
            <goods-filter v-if="showFilter" @change="filterChanged" mini/>
        </div>
        <div v-if="!loading && !goods.length" class="content-body empty">
            暂无单品数据哦
        </div>
        <div v-else-if="loading" class="content-body empty">
            <img src="/assets/images/loading.gif"/>
        </div>
        
        <perfect-scrollbar v-else class="content-body">
            <div class="goods-block" :class="{ 'third': (key + 1) % 3 === 0 }" v-for="(item, key) in goods"
                 :key="item.id">
                
                <button @click="select(item)" class="goods-btn" :class="{ 'selected': idx(item) !== false }">
                    <span>搭</span>
                </button>
                
                <goods-item :goods="item"/>
            
            </div>
        
        </perfect-scrollbar>
        
        <div class="content-footer">
            
            <a-pagination class="st-pagination" v-model="pager.current" :total="pager.total" :page-size="pager.size"
                          @change="pagerClicked"/>
            
            <div v-if="selectedGoods.length">
                <button class="btn btn-white" @click="cancel">取消</button>
                <button class="btn" @click="confirmSelected">确定</button>
            </div>
        </div>
    </div>
</template>

<script>
import GoodsFilter from "@/components/GoodsFilter";
import GoodsItem from "@/components/GoodsItem";
import api from "@/repo/api";

export default {
    name: "GoodsStock",
    components: {GoodsItem, GoodsFilter},
    props: {
        type: String,
        selectedList: Array,
    },
    data() {
        return {
            goods: [],
            loading: false,
            selectedGoods: [],
            filter: {},
            showFilter: true,
            pager: {
                total: 0,
                current: 1,
                size: 10,
            }
        }
    },
    watch: {
        selectedList() {
            this.selectedListChanged()
        }
        
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            
            this.loading = false
            this.goods = []
            
            if (this.type === 'collect') {
                this.showFilter = false
                this.getCollect()
            } else if (this.type === 'goods') {
                this.getGoods()
            } else if (this.type === 'shop') {
                this.showFilter = false
                this.getShopGoods()
            } else if (this.type === 'custom-goods') {
                this.showFilter = false
                this.getCustomGoods()
            }
            
        },
        selectedListChanged() {
            this.selectedGoods = []
            this.initSelected()
        },
        initSelected() {
            
            if (!this.selectedList) return
            
            let goods = JSON.parse(JSON.stringify(this.selectedList))
            
            let selectedGoodsIds = []
            
            this.selectedGoods.forEach(im => {
                
                selectedGoodsIds.push(im.id)
                
            });
            
            goods.forEach(item => {
                
                let idx = selectedGoodsIds.indexOf(item.id)
                
                if (idx >= 0) return
                
                this.selectedGoods.push(item)
            })
            
            this.$forceUpdate()
        },
        getCollect() {
            api.get('/ast-shop/collect-list').then(data => {
                
                this.loading = false
                
                let dta = data.data
                this.goods = this.loadGoodsList(dta.data)
                
                this.pager.current = dta.current_page
                this.pager.total = dta.total
                this.pager.size = dta.per_page
                
                console.log(this.pager);
                
                this.initSelected()
            })
        },
        query() {
            let filter = JSON.parse(JSON.stringify(this.filter));
            filter.page = this.pager.current;
            
            return filter
        },
        getGoods() {
            this.loading = true
            
            console.log(this.query());
            
            api.getGoods(this.query(), (data) => {
                this.loading = false
                
                let dta = data.data
                this.goods = this.loadGoodsList(dta.data)
                
                this.pager.current = dta.current_page
                this.pager.total = dta.total
                this.pager.size = dta.per_page
                
                console.log(this.pager);
                
                this.initSelected()
            })
        },
        getCustomGoods() {
            this.loading = true
            api.get('/ast-goods/goods', this.query(), (data) => {
                this.loading = false
                
                let dta = data.data
                
                this.goods = this.loadGoodsList(dta.data)
                this.pager.current = dta.current_page
                this.pager.total = dta.total
                this.pager.size = dta.per_page
                
                console.log(this.pager);
                
                this.initSelected()
            })
        },
        loadImage(item) {
            item.white_image = item.white_image || item.thumb_image || item.thumb
        },
        loadGoodsList(goods) {
            
            const list = [];
            
            if (!goods && !goods.length) return list
            
            goods.forEach(item => {
                
                this.loadImage(item)
                
                if ((!item.colors) || (!item.colors.length)) {
                    list.push(item)
                    return;
                }
                
                for (let color of item.colors) {
                    
                    let ni = JSON.parse(JSON.stringify(item));
                    
                    ni.white_image = color.white_image || color.thumb
                    ni.title = ni.title + ' - ' + color.color
                    ni.id = ni.id + '-' + color.color
                    ni.is_collect = color.is_collect
                    
                    list.push(ni)
                }
                
            })
            
            return list;
        },
        getShopGoods() {
            api.get('/ast-app/shop-goods', this.query(), (data) => {
                this.loading = false
                
                let dta = data.data
                
                if (!dta) return;
                
                this.goods = this.loadGoodsList(dta.data)
                this.pager.current = dta.current_page
                this.pager.total = dta.total
                this.pager.size = dta.per_page
                
                this.initSelected()
            })
        },
        pagerClicked() {
            this.getData()
        },
        filterChanged(filter) {
            this.filter = filter
            this.pager.current = 1
            this.filter.page = 1
            this.getData()
        },
        select(goods) {
            
            let idx = this.idx(goods);
            
            if (idx !== false) return this.selectedGoods.splice(idx, 1)
            
            this.selectedGoods.push(goods)
        },
        idx(goods) {
            
            for (let goodsKey in this.selectedGoods) {
                let item = this.selectedGoods[goodsKey]
                
                if (item.id === goods.id) return goodsKey
            }
            
            return false;
        },
        cancel() {
            this.selectedGoods = []
            this.confirmSelected()
        },
        confirmSelected() {
            this.$emit('selected', this.selectedGoods)
            this.selectedGoods = []
            
            console.log(this.selectedGoods);
        }
    }
}
</script>

<style lang="less" scoped>
.goods-content {
    height: 100vh;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content-body {
    flex: 1;
    background-color: white;
    position: relative;
    z-index: 3;
    
    &.empty {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 24px;
        color: #333333;
        
        img {
            width: 48px;
            height: 48px;
        }
    }
}

.header-title {
    
    height: 70px;
    
    margin-bottom: 24px;
    
    .cn {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        line-height: 28px;
        margin-bottom: 8px;
    }
    
    .en {
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 28px;
    }
}

.btn-white {
    margin-right: 8px;
}

.content-footer {
    height: 56px;
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
}

.goods-list {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.goods-block {
    margin-right: 25px;
    float: left;
    cursor: pointer;
    min-height: 260px;
    width: 200px;
    margin-bottom: 36px;
    position: relative;
    
    &:hover .goods-btn {
        opacity: 1;
    }
    
    &.third {
        margin-right: 0;
    }
}

.goods-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    background: #FFFFFF;
    border-radius: 100%;
    border: none;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    opacity: 0;
    z-index: 2;
    
    &.selected {
        color: #FFFFFF;
        background: #FFAD00;
        opacity: 1;
    }
}

.shop-goods-item {
    
    .goods-name-box {
        
        margin-bottom: 11px;
        display: flex;
        align-items: center;
        
        .pc-wechat {
            margin-right: 5px;
        }
    }
    
    .goods-name {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        height: 24px;
        overflow: hidden;
        flex: 1;
    }
    
    
    .goods-attr {
        display: flex;
        justify-content: space-between;
    }
    
    .goods-thumb {
        height: 232px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F5F5F5;
        padding: 16px;
        margin-bottom: 16px;
        
        
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    
    .goods-price {
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
        line-height: 12px;
    }
    
    .goods-stock {
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        line-height: 17px;
        font-size: 12px;
    }
}
</style>