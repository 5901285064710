import MP4Box from "mp4box";

const video = {}

video.checkH264 = function (file) {
    return new Promise((resolve,reject) => {

        const reader = new FileReader();

        reader.onload = function (e) {
            const arrayBuffer = e.target.result;

            // Create a MP4Box instance
            const mp4box = MP4Box.createFile();

            // Hook for when the MP4 box is parsed and ready
            mp4box.onReady = function (info) {

                const codecs = mp4box.getCodecs()

                mp4box.flush();

                if (codecs.indexOf('avc') === -1) {
                    reject('请上传H.264（AVC）编码的mp4文件')
                }

                resolve(info);

            };

            arrayBuffer.fileStart = 0;

            // Append the file data to MP4Box and start parsing
            mp4box.appendBuffer(arrayBuffer)

        };

        // Read the file as an ArrayBuffer
        reader.readAsArrayBuffer(file);

    })
}

export default video;