<template>
    <div class="ca-item">
        <div v-if="item.status" class="in-stock">上架中</div>
        <div class="thumb">
            <img :src="item.thumb" alt="">
        </div>
        <div class="ca-content">

            <div class="ca-al">

                <div class="ca-al-1">
                    <div class="ca-title-k">排序序号</div>
                    <div class="ca-title-v">{{ item.sort || '-' }}</div>
                </div>
                <div class="ca-al-2">
                    <div class="ca-title-k">{{ groupName }}标题</div>
                    <div class="ca-title-v">{{ item.title }}</div>
                </div>

            </div>

            <div class="ca-bl">

                <div class="ca-bl-1">
                    <div class="ca-title-k">{{ typeName }}方式</div>
                    <div class="ca-title-v">
                        <span v-if="item.type === 'online'">线上</span>
                        <span v-else-if="item.type === 'video'">视频</span>
                        <span v-else>线下</span>
                    </div>
                </div>
                <div class="ca-bl-2" v-if=" item.price ">
                    <div class="ca-title-k">{{ groupName }}价格</div>
                    <div class="ca-title-v">{{ item.price }}</div>
                </div>
                <div class="ca-bl-3" v-if="item.start">
                    <div class="ca-title-k">{{ groupName }}时间</div>
                    <div class="ca-title-v">{{ start }}</div>
                </div>

            </div>

            <div class="ca-bl" v-if="$app.model !== 'person' ">
                <div class="ca-bl-1">
                    <div class="ca-title-k">报名开启支付</div>
                    <div class="ca-title-v">{{ item.pay_enabled === 1 ? '开启' : '关闭' }}</div>
                </div>
                <div class="ca-bl-2" v-if=" item.pay_enabled ">
                    <div class="ca-title-k">支付价格</div>
                    <div class="ca-title-v">{{ item.pay_amount }}</div>
                </div>


                <div class="ca-bl-2" v-if=" item.share_enabled ">
                    <div class="ca-title-k">分销分成</div>
                    <div class="ca-title-v">{{ item.share_scale }}%</div>
                </div>

                <div class="ca-bl-2" v-if=" item.share_enabled ">
                    <div class="ca-title-k">分销商数</div>
                    <div class="ca-title-v">{{ item.shares_count }}</div>
                </div>
            </div>

            <template v-if=" item.address ">
                <div class="ca-title-k">{{ typeName }}地址</div>
                <div class="ca-title-v">{{ item.address }}</div>
            </template>

            <div class="ca-title-k">{{ groupName }}介绍</div>
            <div class="ca-title-v ca-summary">{{ item.summary }}</div>


            <div class="btn-edit-box">
                <button @click="editPoster" class="btn-edit edit-box-item">
                    <!-- <span class="icon-poster"></span> -->
                    <img class="icon-poster" src="/assets/icons/poster-btn.png" />
                </button>
                <button @click="showQrcode" class="btn-edit qrcode-btn edit-box-item" v-if="item.status">
                    <!-- <span class="icon-xcx"></span> -->
                    <img class="icon-poster" src="/assets/icons/xcx-qrcode-btn.png" />
                </button>
                <button @click="edit" class="btn-edit edit-box-item"><span class="icon-edit"></span></button>
            </div>

            <button v-if="item.content_images" @click="preview" class="btn-content"><span class="icon-tp"></span></button>


            <template v-if="preview">

                <a-modal width="500px" class="img-modal" v-model="showPreview" :footer="null">
                    <div v-for="(image,ik) in item.content_images " :key="ik" style="line-height: 0">
                        <img class="full-with"  :src="image" alt="avatar"/>
                    </div>
                </a-modal>

            </template>
        </div>
    </div>
</template>

<script>

import moment from 'moment';

export default {
    name: "CaItem",
    props: {
        item: Object
    },
    data() {
        return {
            showPreview: false,
            groups: {
                activity: '活动',
                serve: '服务',
                courses: '课程',
            },
            types: {
                activity: '活动',
                serve: '服务',
                courses: '授课',
            },
        }
    },
    methods: {
        preview() {
            this.showPreview = true
        },
        edit() {
            this.$emit('edit', this.item)
        },
        showQrcode() {
            this.$emit('qrcode', this.item)
        },
        editPoster() {
            this.$emit("poster", this.item)
        },
    },
    computed: {
        start() {
            return moment(this.item.start).format('YYYY-MM-DD')
        },
        finish() {
            return moment(this.item.finish).format('YYYY-MM-DD')
        },
        groupName() {
            return this.groups[this.item.group]
        },
        typeName() {
            return this.types[this.item.group]
        }
    }
}
</script>

<style lang="less" scoped>
.in-stock {
    position: absolute;
    left: 0;
    top: 36px;
    background: #FFAD00;
    width: 56px;
    height: 24px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
}

.ca-title-v {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }
}

.ca-title-k {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
}

.ca-item {
    padding: 20px;
    position: relative;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    display: flex;
    max-width: 840px;
}

.thumb {
    margin-right: 24px;

    img {
        width: 224px;
        height: 224px;
    }
}

.ca-bl, .ca-al {
    display: flex;
    justify-content: left;
    flex-direction: row;
    margin-bottom: 24px;
}

.ca-bl-1, .ca-al-1 {
    width: 120px;
}

.ca-bl-2 {
    width: 120px;
}

.ca-bl-3, .ca-al-2 {
    flex: 1;
}

.btn-edit, .btn-content {
    background-color: transparent;
    border: none;
    cursor: pointer;

}

.btn-edit-box {
    position: absolute;
    right: 16px;
    top: 16px;
    justify-content: flex-start;
    display: flex;
    align-items: center;
}

.edit-box-item {
    margin-left: 12px;
}


.btn-content {
    bottom: 60px;
}

.icon-edit, .icon-tp, .icon-xcx {
    display: block;
    width: 24px;
    height: 24px;
    background-image: url("/assets/icons/icon-co-edit.png");
    background-size: 100%;

}

.icon-xcx {
    width: 28px;
    height: 28px;
    background-image: url("/assets/icons/xcx-icon-black.png");
}

.icon-tp {
    background-image: url("/assets/icons/icon-co-tp.png");
}

.icon-poster {
    width: 24px;
    height: 24px;
    // background-image: url("");
}

.img-modal {
    /deep/ .ant-modal {
        top: 0;
        width: 100vw !important;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
    }
}

.ca-content {
    padding-right: 24px;
}

.ca-summary {
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 500px;
}

.qrcode-btn {
    right: 50px;
    top: 14px;
}
</style>